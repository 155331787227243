import moment from 'moment';

function jQueryObj(element) {
  return $(`#${element.id}`);
}

function updateDate(dateElement, newDate) {
  // eslint-disable-next-line no-param-reassign
  dateElement.value = newDate.format('ddd, DD MMM YYYY');
  jQueryObj(dateElement).datepicker('update');
}

function updateOnDateChanged(
  primaryStartDateElement,
  primaryEndDateElement,
  comparisonStartDateElement,
  comparisonEndDateElement,
  autoComparisonCheckboxElement,
) {
  const primaryEventGroupRadioButton = document.getElementById('primary_data_selection_event_group');
  const primaryStartDate = moment(primaryStartDateElement.value);
  const primaryEndDate = moment(primaryEndDateElement.value);
  const comparisonStartDate = moment(comparisonStartDateElement.value);
  const primaryDaysDiff = primaryEndDate.diff(primaryStartDate, 'days');
  const maxComparisonStartDate = moment(primaryStartDateElement.value).subtract(primaryDaysDiff + 1, 'days');

  if (primaryEventGroupRadioButton && primaryEventGroupRadioButton.checked) {
    jQueryObj(comparisonEndDateElement).datepicker('setStartDate', new Date(comparisonStartDate));
    return;
  }

  jQueryObj(comparisonStartDateElement).datepicker('setEndDate', new Date(maxComparisonStartDate));

  if (autoComparisonCheckboxElement.checked === true) {
    const newComparisonStartDate = primaryStartDate.subtract(1, 'year');
    updateDate(comparisonStartDateElement, newComparisonStartDate);
    updateDate(comparisonEndDateElement, newComparisonStartDate.add(primaryDaysDiff, 'days'));
  } else {
    const newComparisonEndDate = comparisonStartDate.add(primaryDaysDiff, 'days');
    updateDate(comparisonEndDateElement, newComparisonEndDate);
  }
}

function updateComparisonStartDateDisabledAttr(comparisonStartDateField, autoComparisonCheckbox) {
  const dateField = comparisonStartDateField;
  dateField.disabled = autoComparisonCheckbox.checked === true;
}

document.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('primary_start_date')) {
    return;
  }

  const primaryStartDateField = document.getElementById('primary_start_date');
  const primaryEndDateField = document.getElementById('primary_end_date');
  const comparisonStartDateField = document.getElementById('comparison_start_date');
  const comparisonEndDateField = document.getElementById('comparison_end_date');
  const autoComparisonCheckbox = document.getElementById('auto_comparison_period');

  function onDateChanged() {
    updateOnDateChanged(
      primaryStartDateField,
      primaryEndDateField,
      comparisonStartDateField,
      comparisonEndDateField,
      autoComparisonCheckbox,
    );
  }

  primaryStartDateField.onchange = onDateChanged;
  primaryEndDateField.onchange = onDateChanged;
  comparisonStartDateField.onchange = onDateChanged;
  autoComparisonCheckbox.onchange = onDateChanged;

  autoComparisonCheckbox.addEventListener('click', () => {
    updateComparisonStartDateDisabledAttr(comparisonStartDateField, autoComparisonCheckbox);
  });
});

export { updateDate, updateOnDateChanged, updateComparisonStartDateDisabledAttr };
