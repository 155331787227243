import DateConstants from './date_constants';

function filterElems() {
  // Get references to the radio buttons and content divs
  // PRIMARY
  const primaryEventGroup = document.getElementById('primary_data_selection_event_group');
  const primaryDateRange = document.getElementById('primary_data_selection_date_range');
  const primaryEventGroupContent = document.getElementById('primary_event_group_content');
  const primaryDateRangeContent = document.getElementById('primary_date_range_content');
  const primaryStartDateField = document.getElementById('primary_start_date');
  const primaryEndDateField = document.getElementById('primary_end_date');
  // COMPARISON
  const comparisonEventGroup = document.getElementById('comparison_data_selection_event_group');
  const comparisonDateRange = document.getElementById('comparison_data_selection_date_range');
  const comparisonDateRangeWrapper = document.getElementById('comparison_date_range_wrapper');
  const comparisonEventGroupContent = document.getElementById('comparison_event_group_content');
  const comparisonDateRangeContent = document.getElementById('comparison_date_range_content');
  const comparisonDateRangeAutoContent = document.getElementById('comparison_date_range_auto_content');
  const autoComparisonCheckbox = document.getElementById('auto_comparison_period');
  const comparisonStartDateField = document.getElementById('comparison_start_date');
  const comparisonEndDateField = document.getElementById('comparison_end_date');
  const comparisonDateTipText = document.getElementById('comparison_period_date_tip');

  return {
    primaryEventGroup,
    primaryDateRange,
    primaryEventGroupContent,
    primaryDateRangeContent,
    primaryStartDateField,
    primaryEndDateField,
    comparisonEventGroup,
    comparisonDateRange,
    comparisonDateRangeWrapper,
    comparisonEventGroupContent,
    comparisonDateRangeContent,
    comparisonDateRangeAutoContent,
    autoComparisonCheckbox,
    comparisonStartDateField,
    comparisonEndDateField,
    comparisonDateTipText,
  };
}

function jQueryObj(element) {
  return $(`#${element.id}`);
}

function primaryDateRangeChanged() {
  const elems = filterElems();
  if (elems.primaryDateRange.checked) {
    elems.primaryEventGroupContent.style.display = 'none';
    elems.primaryDateRangeContent.style.display = 'block';
    elems.comparisonDateRangeAutoContent.style.display = 'flex';
    elems.comparisonStartDateField.disabled = elems.autoComparisonCheckbox.checked;
    elems.comparisonEndDateField.disabled = true;
    elems.comparisonEndDateField.readOnly = true;
    elems.comparisonDateTipText.style.display = 'block';
  }
}

function primaryEventGroupChanged() {
  const elems = filterElems();
  if (elems.primaryEventGroup.checked) {
    elems.primaryEventGroupContent.style.display = 'block';
    elems.primaryDateRangeContent.style.display = 'none';
    elems.comparisonDateRangeAutoContent.style.display = 'none';
    elems.comparisonStartDateField.disabled = false;
    elems.comparisonEndDateField.disabled = false;
    elems.comparisonEndDateField.readOnly = false;
    elems.comparisonDateTipText.style.display = 'none';
    if (elems.comparisonDateRange.checked) {
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setEndDate', new Date());
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setEndDate', new Date());
    } else {
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setEndDate', new Date(elems.primaryStartDateField.value));
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setEndDate', new Date(elems.primaryStartDateField.value));
    }
  }
}

function comparisonDateRangeChanged() {
  const elems = filterElems();
  if (elems.comparisonDateRange.checked) {
    elems.comparisonEventGroupContent.style.display = 'none';
    elems.comparisonDateRangeContent.style.display = 'block';
    elems.comparisonDateRangeWrapper.style.display = 'block';
    elems.comparisonDateRangeAutoContent.style.display = elems.primaryDateRange.checked ? 'block' : 'none';
    elems.comparisonStartDateField.disabled = elems.primaryDateRange.checked
      && elems.autoComparisonCheckbox.checked;
    elems.comparisonEndDateField.disabled = elems.primaryDateRange.checked;
    elems.comparisonEndDateField.readOnly = elems.primaryDateRange.checked;
    elems.comparisonDateTipText.style.display = elems.primaryDateRange.checked;
    if (elems.primaryDateRange.checked) {
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setEndDate', new Date(elems.primaryStartDateField.value));
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setEndDate', new Date(elems.primaryStartDateField.value));
    } else {
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setStartDate', new Date(DateConstants.earliestPerformanceDateWithTransactions));
      jQueryObj(elems.comparisonStartDateField)
        .datepicker('setEndDate', new Date());
      jQueryObj(elems.comparisonEndDateField)
        .datepicker('setEndDate', new Date());
    }
  }
}

function comparisonEventGroupChanged() {
  const elems = filterElems();
  if (elems.comparisonEventGroup.checked) {
    elems.comparisonEventGroupContent.style.display = 'block';
    elems.comparisonDateRangeContent.style.display = 'none';
    elems.comparisonDateRangeWrapper.style.display = 'none';
    elems.comparisonDateRangeAutoContent.style.display = 'none';
    elems.comparisonStartDateField.disabled = false;
    elems.comparisonEndDateField.disabled = false;
    elems.comparisonEndDateField.readOnly = false;
    elems.comparisonDateTipText.style.display = 'none';
  }
}

// TODO: functions for each of the buttons' event listeners to call, and export them at the bottom
// of the file for better testability.

document.addEventListener('DOMContentLoaded', () => {
  const elems = filterElems();

  // Don't run on page without a full event group filter
  let filteringControlsOnPage = true;
  Object.values(elems).forEach((value) => {
    if (!value) filteringControlsOnPage = false;
  });
  if (!filteringControlsOnPage) return;

  // Add event listeners to the radio buttons to toggle content
  // TODO: If these are ever changed, update the ERB templates' logic for style/attribute values as
  //  well, to ensure consistency between the statically-rendered page and its dynamic behaviour.

  elems.primaryDateRange.addEventListener('change', () => {
    primaryDateRangeChanged();
  });

  elems.primaryEventGroup.addEventListener('change', () => {
    primaryEventGroupChanged();
  });

  elems.comparisonDateRange.addEventListener('change', () => {
    comparisonDateRangeChanged();
  });

  elems.comparisonEventGroup.addEventListener('change', () => {
    comparisonEventGroupChanged();
  });
});

export {
  primaryDateRangeChanged,
  primaryEventGroupChanged,
  comparisonDateRangeChanged,
  comparisonEventGroupChanged,
  filterElems,
};
